/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ChatRoom } from '../models/ChatRoom';
import type { ChatRoomPartial } from '../models/ChatRoomPartial';
import type { ChatRoomWithRelations } from '../models/ChatRoomWithRelations';
import type { NewChatRoom } from '../models/NewChatRoom';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ChatRoomControllerService {
  /**
   * @returns any Coach chat list
   * @throws ApiError
   */
  public static chatRoomControllerGetCoachChatList({
    id,
    type,
    filter,
    page,
    search,
  }: {
    id: string;
    type?: 'direct' | 'group';
    filter?: any;
    page?: number;
    search?: string;
  }): CancelablePromise<{
    currentPage?: number;
    totalPages?: number;
    count?: number;
    status?: string;
    data?: Array<ChatRoomWithRelations>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/chat-rooms/coach-user-list/{id}',
      path: {
        id: id,
      },
      query: {
        type: type,
        filter: filter,
        page: page,
        search: search,
      },
    });
  }

  /**
   * @returns any ChatRoom model count
   * @throws ApiError
   */
  public static chatRoomControllerCount({ where }: { where?: any }): CancelablePromise<{
    count?: number;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/chat-rooms/count',
      query: {
        where: where,
      },
    });
  }

  /**
   * @returns ChatRoomWithRelations ChatRoom model instances
   * @throws ApiError
   */
  public static chatRoomControllerGetSingleChatroom({
    id,
    type,
  }: {
    id: string;
    type?: 'user' | 'coach';
  }): CancelablePromise<ChatRoomWithRelations> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/chat-rooms/single-room/{id}',
      path: {
        id: id,
      },
      query: {
        type: type,
      },
    });
  }

  /**
   * @returns any Chat settings summary
   * @throws ApiError
   */
  public static chatRoomControllerGetSummary({
    id,
    userId,
  }: {
    id: string;
    userId?: string;
  }): CancelablePromise<{
    chatRoom?: ChatRoom;
    resources?: Array<string>;
    users?: Array<{
      id?: string;
      name?: string;
      email?: string;
      phone?: string;
      image?: string;
    }>;
    coach?: {
      id?: string;
      name?: string;
      image?: string;
    };
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/chat-rooms/summary/{id}',
      path: {
        id: id,
      },
      query: {
        userId: userId,
      },
    });
  }

  /**
   * @returns ChatRoomWithRelations User last chats
   * @throws ApiError
   */
  public static chatRoomControllerGetChatUserList({
    id,
  }: {
    id: string;
  }): CancelablePromise<Array<ChatRoomWithRelations>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/chat-rooms/userList/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static chatRoomControllerReplaceById({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: ChatRoom;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/chat-rooms/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static chatRoomControllerUpdateById({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: ChatRoomPartial;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/chat-rooms/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns ChatRoomWithRelations ChatRoom model instance
   * @throws ApiError
   */
  public static chatRoomControllerFindById({
    id,
    filter,
  }: {
    id: string;
    filter?: any;
  }): CancelablePromise<ChatRoomWithRelations> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/chat-rooms/{id}',
      path: {
        id: id,
      },
      query: {
        filter: filter,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static chatRoomControllerDeleteById({ id }: { id: string }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/chat-rooms/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns ChatRoom ChatRoom model instance
   * @throws ApiError
   */
  public static chatRoomControllerCreate({
    requestBody,
  }: {
    requestBody?: NewChatRoom;
  }): CancelablePromise<ChatRoom> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/chat-rooms',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any ChatRoom PATCH success count
   * @throws ApiError
   */
  public static chatRoomControllerUpdateAll({
    where,
    requestBody,
  }: {
    where?: any;
    requestBody?: ChatRoomPartial;
  }): CancelablePromise<{
    count?: number;
  }> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/chat-rooms',
      query: {
        where: where,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns ChatRoomWithRelations Array of ChatRoom model instances
   * @throws ApiError
   */
  public static chatRoomControllerFind({
    filter,
  }: {
    filter?: any;
  }): CancelablePromise<Array<ChatRoomWithRelations>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/chat-rooms',
      query: {
        filter: filter,
      },
    });
  }
}
