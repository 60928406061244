// @ts-ignore

import moment from 'moment';
import { NotificationManager } from 'react-notifications';

export const findIndexInArray = (originalArray, objectToFInd, key, key2?: string | undefined) => {
  return originalArray.findIndex((item) => {
    if (key2) return item[key][key2] === objectToFInd[key][key2];
    else return item[key] === objectToFInd[key];
  });
};

export const healthPassportLocationOptions = [
  { label: 'Outreach', value: 'Outreach' },
  { label: 'Hospital', value: 'Hospital' },
  { label: 'Primary Health Centre', value: 'Primary Health Centre' },
  { label: 'Laboratory', value: 'Laboratory' },
];

export const actionsMap = {
  edit: 'edit',
  delete: 'delete',
  add: 'add',
};

export const returnUpdatedList = (newObj, oldList, key = 'id', key2 = '') => {
  const indexOfLocal = findIndexInArray(oldList, newObj, key, key2);
  if (indexOfLocal !== -1) oldList[indexOfLocal] = newObj;
  return oldList;
};

export const removeDuplicates = (myArr: any[], prop: any) => {
  return myArr.filter((obj, pos, arr) => {
    return arr.map((mapObj) => mapObj[prop]).indexOf(obj[prop]) === pos;
  });
};

export const scrollToSection = (id) => {
  const section = document.getElementById(id);
  section.scrollIntoView({ behavior: 'smooth' });
};

//rounds up a number(value) to x decimal places
// it returns a whole number when value is whole
export const roundUp = (value: number, x: number) => {
  const multiplier = Math.pow(10, x || 0);
  return Math.round(value * multiplier) / multiplier;
};

// function to add st, nd, rd, th to positions
export const postion = (number: number) => {
  const lastDigit = number % 10,
    k = number % 100;

  if (lastDigit === 1 && k !== 11) {
    return number + 'st';
  } else if (lastDigit === 2 && k !== 12) {
    return number + 'nd';
  } else if (lastDigit === 3 && k !== 13) {
    return number + 'rd';
  } else {
    return number + 'th';
  }
};

export const getUrlParam = (param: string) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(param);
};

export const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
};

export function waitForDomElement(selector) {
  return new Promise((resolve) => {
    if (document.querySelector(selector)) {
      return resolve(document.querySelector(selector));
    }
    const observer = new MutationObserver((mutations) => {
      if (document.querySelector(selector)) {
        resolve(document.querySelector(selector));
        observer.disconnect();
      }
    });
    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
  });
  // Usage
  // const help_button = await waitForDomElement('#help-btn');
}

export const calculateDatePercentage = (startDate: Date, endDate: Date): number => {
  const now = new Date().getTime();
  const start = startDate.getTime();
  const end = endDate.getTime();
  const range = end - start;
  const val = Math.ceil((100 * (now - start)) / range);
  return val > 100 ? 100 : 0 > val ? 0 : val;
};

export const formatDate = (dateType: Date | string) => {
  const date = moment(dateType);
  const now = moment();
  if (now.diff(date, 'days') < 7) {
    return date.fromNow(); // Output: a week ago
  } else if (now.diff(date, 'months') < 1) {
    return date.fromNow(); // Output: 1 week ago
  } else {
    const formattedDate = date.format('h:mm A, MMMM D');
    return formattedDate;
  }
};

export const renderNewColor = () => {
  const colorArray = [
    '#f26522',
    '#02b176',
    '#000000',
    '#7e7e7e',
    '#DD9787',
    '#3DCCC7',
    '#E75A7C',
    '#632A50',
    '#DE6449',
    '#745296',
  ];
  const chosenColor = Math.floor(Math.random() * colorArray.length);
  return colorArray[chosenColor];
};

export const linkify = (inputText) => {
  //URLs starting with http://, https://, or ftp://
  const replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
  let replacedText = inputText.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>');

  //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
  const replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
  replacedText = replacedText.replace(
    replacePattern2,
    '$1<a href="http://$2" target="_blank">$2</a>',
  );

  //Change email addresses to mailto:: links.
  const replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
  replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>');

  return replacedText;
};

export const decryptEmail = (salt, encoded) => {
  const textToChars = (text) => text.split('').map((c) => c.charCodeAt(0));
  const applySaltToChar = (code) => textToChars(salt).reduce((a, b) => a ^ b, code);
  return encoded
    .match(/.{1,2}/g)
    .map((hex) => parseInt(hex, 16))
    .map(applySaltToChar)
    .map((charCode) => String.fromCharCode(charCode))
    .join('');
};

export const isEmpty = (value: any) => {
  return value == null || (typeof value === 'string' && value.trim().length === 0);
};

export const Notify = NotificationManager;

export const getUniqueObjects = (arr) => {
  return arr.filter((obj, index, array) => {
    // Use indexOf for older browsers or includes for modern browsers
    return array.findIndex((o) => o.id === obj.id) === index;
  });
};

export const getSecondsToHoursMinAndSecs = (seconds: number) => {
  const hour = Math.floor(seconds / 3600);
  const remainingSeconds = seconds % 3600;
  const min = Math.floor(remainingSeconds / 60);
  const sec = remainingSeconds % 60;

  const hourWithZeroPrefix = hour < 10 ? `0${hour}` : hour;
  const minWithZeroPrefix = min < 10 ? `0${min}` : min;
  const secWithZeroPrefix = sec < 10 ? `0${sec}` : sec;

  return {
    hour,
    min,
    sec,
    hourWithZeroPrefix,
    minWithZeroPrefix,
    secWithZeroPrefix,
  };
};

export const generateRandomCode = () => {
  const digits = '123456789';
  let reference = '';

  for (let i = 0; i < 6; i++) {
    const randomIndex = Math.floor(Math.random() * digits.length);
    reference += digits[randomIndex];
  }
  return reference;
};

export const getFormattedDate = (response, format = 'Do MMM YYYY') => {
  return response ? moment(response).format(format) : '--/--';
};
export const getFormattedTime = (response, format = 'H:mm a') => {
  return response ? moment(response).format(format) : '';
};

export const getMultipleChoiceValue = (response): string => {
  return response ? response : '';
};

export const capitalizeFirstLetter = (text) => text.charAt(0).toUpperCase() + text.slice(1);

export const slugify = (str: string) => {
  return String(str)
    .normalize('NFKD') // split accented characters into their base characters and diacritical marks
    .replace(/[\u0300-\u036f]/g, '') // remove all the accents, which happen to be all in the \u03xx UNICODE block.
    .trim() // trim leading or trailing whitespace
    .toLowerCase() // convert to lowercase
    .replace(/[^a-z0-9 -]/g, '') // remove non-alphanumeric characters
    .replace(/\s+/g, '-') // replace spaces with hyphens
    .replace(/-+/g, '-'); // remove consecutive hyphens
};

export const onlyNumbers = (e: any) => {
  const num = /[^0-9]/gi;
  e.target.value = e.target.value.replace(num, '');
};

export const today = new Date();
export const getStartOfDay = (date) => {
  return moment(date).utc().startOf('day').toISOString();
};
export const getEndOfDay = (date) => {
  return moment(date).utc().endOf('day').toISOString();
};
