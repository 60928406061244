import { ArrowBack } from '@mui/icons-material';
import { Avatar } from '@mui/material';
import { ChatRoom } from 'generated';
import { ChatRoomSummaryResponse } from 'pages/chatPage/hooks/useGetChatRoomSummary';
import { IDisclosure } from 'utils/useDisclosure/useDisclosure';

interface IProps {
  activeChatRoom: ChatRoom;
  chatDrawerHandler: IDisclosure;
  chatRoomSummary?: ChatRoomSummaryResponse;
  handleActiveChatroom?: (chatroom?: ChatRoom) => void;
}

const ChatTopBar = ({
  activeChatRoom,
  chatDrawerHandler,
  chatRoomSummary,
  handleActiveChatroom,
}: IProps) => {
  const isGroup = activeChatRoom?.type === 'group';
  const roomName = isGroup ? activeChatRoom?.groupName : chatRoomSummary?.users[0]?.name;

  const image = isGroup ? activeChatRoom?.imageUrl : chatRoomSummary?.users[0]?.image;

  return (
    <section className="bg-white border-b">
      <div className="p-5 flex items-center justify-between">
        <div className="flex items-center space-x-5">
          <div onClick={() => handleActiveChatroom?.(null)} className="flex md:hidden">
            <ArrowBack className="h-10 w-10" />
          </div>
          <Avatar className={`rounded full`} alt={roomName} src={image} />
          <div>
            <span
              className={`font-bold block text-sm text-primaryBlue ${!isGroup ? 'capitalize' : ''}`}
            >
              {roomName}
            </span>
            {/* <span className={`block text-xs text-gray-400`}>Offline</span> */}
          </div>
        </div>
        <div>
          {activeChatRoom.type === 'group' ? (
            <div className="cursor-pointer" onClick={() => chatDrawerHandler.onOpen()}>
              <i className="mDoc-setting-2" />
            </div>
          ) : (
            <div className="cursor-pointer" onClick={() => chatDrawerHandler.onOpen()}>
              <i className="mDoc-sidebar-left" />
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default ChatTopBar;
