/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NewPayments } from '../models/NewPayments';
import type { Payments } from '../models/Payments';
import type { PaymentsPartial } from '../models/PaymentsPartial';
import type { PaymentsWithRelations } from '../models/PaymentsWithRelations';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PaymentsControllerService {
  /**
   * @returns any Payments  success
   * @throws ApiError
   */
  public static paymentsControllerGetFinanceSummary({
    filter,
    startDate,
    endDate,
  }: {
    filter?: string;
    startDate?: string;
    endDate?: string;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/getFinanceSummary',
      query: {
        filter: filter,
        startDate: startDate,
        endDate: endDate,
      },
    });
  }

  /**
   * @returns any Payments  success
   * @throws ApiError
   */
  public static paymentsControllerBillingHistory(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/payments/billingHistory',
    });
  }

  /**
   * @returns any Payments model count
   * @throws ApiError
   */
  public static paymentsControllerCount({ where }: { where?: any }): CancelablePromise<{
    count?: number;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/payments/count',
      query: {
        where: where,
      },
    });
  }

  /**
   * @returns any Payments  success
   * @throws ApiError
   */
  public static paymentsControllerCreateFlutterPayment({
    planId,
    transactionId,
    amount,
  }: {
    planId?: string;
    transactionId?: string;
    amount?: string;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/payments/createFlutterPayment',
      query: {
        planId: planId,
        transactionId: transactionId,
        amount: amount,
      },
    });
  }

  /**
   * @returns any Payments Customer Details success
   * @throws ApiError
   */
  public static paymentsControllerCreatePayment({
    requestBody,
  }: {
    requestBody?: any;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/payments/createPayment',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any Payments  success
   * @throws ApiError
   */
  public static paymentsControllerGetCustomerDetails({
    requestBody,
  }: {
    requestBody?: any;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/payments/getCustomerDetails',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns Payments Payments model instance
   * @throws ApiError
   */
  public static paymentsControllerInitializePayment({
    requestBody,
  }: {
    requestBody?: NewPayments;
  }): CancelablePromise<Payments> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/payments/initialize',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any[] Array of Payments model instances
   * @throws ApiError
   */
  public static paymentsControllerPaymentsChart({
    startDate,
    endDate,
  }: {
    startDate?: string;
    endDate?: string;
  }): CancelablePromise<any[]> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/payments/paymentsChart',
      query: {
        startDate: startDate,
        endDate: endDate,
      },
    });
  }

  /**
   * @returns any
   * @throws ApiError
   */
  public static paymentsControllerUpload({
    formData,
  }: {
    /**
     * multipart/form-data value.
     */
    formData: any;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/payments/upload',
      formData: formData,
      mediaType: 'multipart/form-data',
    });
  }

  /**
   * @returns any Payments  success
   * @throws ApiError
   */
  public static paymentsControllerVerifyTransaction({
    requestBody,
  }: {
    requestBody?: any;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/payments/verifyTransaction',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static paymentsControllerReplaceById({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: Payments;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/payments/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static paymentsControllerUpdateById({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: PaymentsPartial;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/payments/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns PaymentsWithRelations Payments model instance
   * @throws ApiError
   */
  public static paymentsControllerFindById({
    id,
    filter,
  }: {
    id: string;
    filter?: any;
  }): CancelablePromise<PaymentsWithRelations> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/payments/{id}',
      path: {
        id: id,
      },
      query: {
        filter: filter,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static paymentsControllerDeleteById({ id }: { id: string }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/payments/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns any Payments PATCH success count
   * @throws ApiError
   */
  public static paymentsControllerUpdateAll({
    where,
    requestBody,
  }: {
    where?: any;
    requestBody?: PaymentsPartial;
  }): CancelablePromise<{
    count?: number;
  }> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/payments',
      query: {
        where: where,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any Array of UserSubscriptions model instances
   * @throws ApiError
   */
  public static paymentsControllerGetAllPayments({
    limit,
    page,
    startDate,
    endDate,
    userId,
    programId,
    status,
    hubVisitId,
    nudgeHubId,
  }: {
    limit?: number;
    page?: number;
    startDate?: string;
    endDate?: string;
    userId?: string;
    programId?: string;
    status?: string;
    hubVisitId?: string;
    nudgeHubId?: string;
  }): CancelablePromise<{
    currentPage?: number;
    totalPages?: number;
    count?: number;
    status?: string;
    data?: Array<PaymentsWithRelations>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/payments',
      query: {
        limit: limit,
        page: page,
        startDate: startDate,
        endDate: endDate,
        userId: userId,
        programId: programId,
        status: status,
        hubVisitId: hubVisitId,
        nudgeHubId: nudgeHubId,
      },
    });
  }
}
